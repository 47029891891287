import '../scss/carousel-ring-icons.scss'

/**
 * Initializes carousels when the 'CarouselUtilsReady' event is fired.
 *
 * This function listens for the 'CarouselUtilsReady' event, which indicates that the FWDU3DCar utilities are ready.
 * It then selects all carousels with the `.carousel-ring-icons` class and initializes each one using the `carouselInit` function,
 * with specific configuration parameters for each carousel.
 *
 * @listens CarouselUtilsReady - The event dispatched when the carousel utilities are ready.
 */
document.addEventListener('CarouselUtilsReady', () => {
    if (window.carouselRingIconsInit) {
        return;
    }

    const carouselRingIcons = document.querySelectorAll('.carousel-ring-icons');
    carouselRingIcons.forEach((carousel, index) => {
        const carouselInstanceId = carousel.id;
        const carouselHolderId = carousel.querySelector('.carousel-ring-icons__holder').id;
        const carouselDataId = carousel.querySelector('.carousel-ring-icons__data').id;
        const slides = carousel.querySelectorAll('.carousel-ring-icons__data ul').length;
        const slideshowDelay = parseInt(carousel.dataset.delay);

        // Initializes the carousel with the necessary configurations
        carouselInit({
            // Main.
            instanceName:carouselInstanceId,
            carouselHolderDivId:carouselHolderId,
            carouselDataListDivId:carouselDataId,
            displayType:"responsive",
            carouselWidth:1400,
            carouselOffsetHeight:120,
            thumbnailResizeOffest:100,
            zIndex:0,
            mainFolderPath:"/assets/css/plugins/carousel",
            skinPath:"skin_white",
            useVectorIcons:"no",
            initializeOnlyWhenVisible:"no",
            carouselStartPosition:"center",
            carouselTopology:"ring",
            carouselXRadius: calculateCarouselXRadius(slides),
            carouselYRadius:0,
            carouselXRotation:9,
            carouselYOffset:0,
            showCenterImage:"no",
            centerImagePath:"",
            centerImageYOffset:0,
            showDisplay2DAlways:"no",
            rightClickContextMenu:"default",
            enableMouseWheelScroll:"no",
            addKeyboardSupport:"yes",
            useDragAndSwipe:"yes",
            backgroundColor:"transparent",
            backgroundImage:"",
            backgroundImageRepeat:'no-repeat',
            backgroundImageSize:"auto",
            preloaderBackgroundColor:"#333333",
            preloaderFillColor:"#FFFFFF",

            // Thumbnails.
            thumbnailWidth:150,
            thumbnailHeight:150,
            thumbnailBorderSize:0,
            thumbnailMinimumAlpha:.4,
            thumbnailBackgroundColor:"transparent",
            thumbnailBorderColor1:"transparent",
            thumbnailBorderColor2:"transparent",
            transparentImages:"no",
            showReflection:"no",
            reflectionHeight:60,
            reflectionDistance:0,
            reflectionOpacity:.15,
            useVideo:"yes",
            videoAutoPlay:"no",
            nextVideoAutoPlay:"no",
            videoAutoPlayText:"Click to unmute",
            volume:1,
            showLogo:"yes",
            logoPath:"content/rl/content/evp/skin/logo.png",
            logoLink:"",
            fillEntireVideoScreen:"yes",
            showDefaultControllerForVimeo:"yes",
            showScrubberWhenControllerIsHidden:"yes",
            showVolumeButton:"yes",
            showTime:"yes",
            showRewindButton:"no",
            showQualityButton:"no",
            showPlaybackRateButton:"yes",
            showChromecastButton:"no",
            showFullScreenButton:"yes",
            showScrubberToolTipLabel:"yes",
            timeColor:"#B9B9B9",
            youtubeQualityButtonNormalColor:"#B9B9B9",
            youtubeQualityButtonSelectedColor:'#FFFFFF',
            scrubbersToolTipLabelBackgroundColor:'#FFFFFF',
            scrubbersToolTipLabelFontColor:'#5a5a5a',
            audioVisualizerLinesColor:'#D60E63',
            audioVisualizerCircleColor:'#FFFFFF',
            thumbnailsPreviewWidth:196,
            thumbnailsPreviewBackgroundColor:'#2e2e2e',
            thumbnailsPreviewBorderColor:'#414141',
            thumbnailsPreviewLabelBackgroundColor:'#414141',
            thumbnailsPreviewLabelFontColor:'#CCCCCC',
            skipToVideoText:"You can skip to video in: ",
            skipToVideoButtonText:"Skip Ad",

            // Controls.
            controlsMaxWidth:600,
            controlsOffset:0,
            showNextAndPrevButtons:"yes",
            showNextAndPrevButtonsOnMobile:"no",
            nextAndPrevButtonsOffsetX:20,
            showLargeNextAndPrevButtons:"no",
            largeNextAndPrevButtonsMaxWidthPos:1300,
            showSlideshowButton:"no",
            slideshowAutoplay: slideshowDelay ? "yes" : "no",
            slideshowDelay:slideshowDelay,
            slideshowPreloaderBackgroundColor:"#333333",
            slideshowPreloaderFillColor:"#FFFFFF",
            showScrollbar:"no",
            showScrollbarOnMobile:"no",
            scrollbarHandlerWidth:200,
            scrollbarTextColorNormal:"#FFFFFF",
            scrollbarTextColorSelected:"#FFFFFF",
            showBulletsNavigation:"no",
            bulletsNormalColor:"#D2D2D2",
            bulletsSelectedColor:"#333333",
            bulletsNormalRadius:6,
            bulletsSelectedRadius:9,
            spaceBetweenBullets:18,

            // Caption.
            showCaption:"yes",
            captionPosition:"out",
            captionAnimationType:"opacity",
            showCaptionOnTap:"no",
            showFullCaption:"no",

            // Menu.
            showMenu:"no",
            startAtCategory:2,
            menuPosition:"topright",
            selectorLineColor:"#D1D1D1",
            selectorBackgroundColor:"#EDEDED",
            selectorTextNormalColor:"#111111",
            selectorTextSelectedColor:"#111111",
            buttonBackgroundColor:"#EDEDED",
            buttonTextNormalColor:"#717171",
            buttonTextSelectedColor:"#111111",
            menuHorizontalMargins:12,
            menuVerticalMargins:12,

            // Lightbox.
            useLightbox:"no",
            rlUseDeepLinking:"yes",
            rlItemBackgroundColor:"#212121",
            rlDefaultItemWidth:1527,
            rlDefaultItemHeight:859,
            rlItemOffsetHeight:37,
            rlItemOffsetHeightButtonsTop:47,
            rlItemBorderSize:0,
            rlItemBorderColor:"#FFFFFF",
            rlMaxZoom:1.2,
            rlPreloaderBkColor:"#2e2e2e",
            rlPreloaderFillColor:"#FFFFFF",
            rlButtonsAlignment:"in",
            rlButtonsHideDelay:5,
            rlMediaLazyLoading:"yes",
            rlUseDrag:"yes",
            rlUseAsModal:"no",
            rlShowSlideShowButton:"yes",
            rlShowSlideShowAnimation:"yes",
            rlSlideShowAutoPlay:"no",
            rlSlideShowAutoStop:"no",
            rlSlideShowDelay:6,
            rlSlideShowBkColor:"#2e2e2e",
            rlSlideShowFillColor:"#FFFFFF",
            rlUseKeyboard:"yes",
            rlUseDoubleClick:"yes",
            rlShowCloseButton:"yes",
            rlShowFullscreenButton:"yes",
            rlShowZoomButton:"yes",
            rlShowCounter:"yes",
            rlShowNextAndPrevBtns:"yes",
            rlSpaceBetweenBtns:8,
            rlButtonsOffsetIn:10,
            rlButtonsOffsetOut:10,
            rlBackgroundColor:"rgba(0,0,0,.99)",
            rlShareButtons:['facebook', 'twitter', 'linkedin', 'tumblr', 'pinterest', 'reddit', 'buffer', 'digg','blogger'],
            rlShareText:"Share on",
            rlSharedURL:"deeplink",
            rlShareMainBackgroundColor:"rgba(0,0,0,.4)",
            rlShareBackgroundColor:"#FFFFFF",
            rlShowThumbnails:"yes",
            rlShowThumbnailsIcon:"yes",
            rlThumbnailsHeight:80,
            rlThumbnailsOverlayColor:"rgba(0,0,0,.4)",
            rlThumbnailsBorderSize:2,
            rlThumbnailsBorderColor:"#FFFFFF",
            rlSpaceBetweenThumbnailsAndItem:10,
            rlThumbnailsOffsetBottom:10,
            rlSpaceBetweenThumbnails:5,
            rlShowCaption:"yes",
            rlCaptionPosition:"bottomout",
            rlShowCaptionOnSmallScreens:"no",
            rlCaptionAnimationType:"motion",
            rlUseVideo:"yes",
            rlFillEntireScreenWithPoster:"yes",
            rlVolume:1,
            rlVideoAutoPlay:"no",
            rlNextVideoAutoPlay:"no",
            rlVideoAutoPlayText:"Click to unmute",
            rlShowLogo:"yes",
            rlLogoPath:"content/rl/content/evp/skin/logo.png",
            rlLogoLink:"",
            rlShowDefaultControllerForVimeo:"yes",
            rlShowScrubberWhenControllerIsHidden:"yes",
            rlShowRewindButton:"yes",
            rlShowVolumeButton:"yes",
            rlShowChromecastButton:"yes",
            rlShowPlaybackRateButton:"no",
            rlShowQualityButton:"yes",
            rlShowFullScreenButton:"yes",
            rlShowScrubberToolTipLabel:"yes",
            rlShowTime:"yes",
            rlTimeColor:"#B9B9B9",
            rlYoutubeQualityButtonNormalColor:"#B9B9B9",
            rlYoutubeQualityButtonSelectedColor:"#FFFFFF",
            rlScrubbersToolTipLabelBackgroundColor:"#FFFFFF",
            rlScrubbersToolTipLabelFontColor:"#5a5a5a",
            rlAudioVisualizerLinesColor:"#D60E63",
            rlAudioVisualizerCircleColor:"#FFFFFF",
            rlThumbnailsPreviewWidth:196,
            rlThumbnailsPreviewBackgroundColor:"#2e2e2e",
            rlThumbnailsPreviewBorderColor:"#414141",
            rlThumbnailsPreviewLabelBackgroundColor:"#414141",
            rlThumbnailsPreviewLabelFontColor:"#CCCCCC",
            rlSkipToVideoText:"You can skip to video in: ",
            rlSkipToVideoButtonText:"Skip Ad"
        });
    });

    window.carouselRingIconsInit = true;
});

/**
 * Dynamically calculates the carousel X-axis radius based on the number of slides.
 *
 * This function adjusts the X-axis radius for the carousel by starting with a base radius
 * for a minimum number of slides (defaulting to 5) and incrementing the radius for each additional slide.
 *
 * @param {number} slides - The number of slides in the carousel.
 * @returns {number} The calculated X-axis radius for the carousel.
 */
function calculateCarouselXRadius(slides) {
    const baseRadius = 400;
    const incrementPerSlide = 90;
    const minSlides = 5;

    if (slides < minSlides) slides = minSlides;

    return baseRadius + (slides - minSlides) * incrementPerSlide;
}